import { FacebookIcon, LinkedinIcon, TwitterIcon } from "lucide-react"
import * as React from "react"
import { Link } from "react-router-dom"
import {
  salesProUrl,
  topSponsorsIn2021Path,
  topSponsorsIn2022Path,
  topSponsorsIn2023Path,
  topSponsorsIn2024Path,
  topSponsorsInAIPath,
  topSponsorsInB2BPath,
  topSponsorsInCryptoAndWeb3Path,
  topSponsorsInEducationPath,
  topSponsorsInHealthAndFitnessPath,
  topSponsorsInInvestingPath,
  topSponsorsInMarketingPath,
  topSponsorsInMensInterestPath,
  topSponsorsInSoftwarePath,
  topSponsorsInSportsPath,
  topSponsorsInWomensInterestPath,
  topSponsorsPath,
} from "~/common/paths"
import { cn } from "~/lib/utils"

export const Footer = () => {
  return (
    <div className="bg-primary text-primary-foreground">
      <div className="max-w-page mx-auto w-full p-5 md:p-10">
        <div className="flex justify-between items-stretch gap-5 md:gap-2 flex-col md:flex-row">
          <div className="flex-1">
            <FooterHeader>Company</FooterHeader>
            <FooterItem>
              <a href="https://site.whosponsorsstuff.com/about">About</a>
            </FooterItem>
            <FooterItem>
              <a href="mailto:hello@whosponsorsstuff.com">Contact</a>
            </FooterItem>
          </div>
          <div className="flex-1">
            <FooterHeader>Product</FooterHeader>
            <FooterItem>
              <a href={salesProUrl}>Sales Pro</a>
            </FooterItem>
            <FooterItem>
              <a href="https://site.whosponsorsstuff.com/newsletter-signup/">
                Newsletter
              </a>
            </FooterItem>
          </div>
          <div className="flex-1">
            <FooterHeader>Tools & Resources</FooterHeader>{" "}
            <FooterItem>
              <a href="https://site.whosponsorsstuff.com/newsletter-ad-calculator">
                Newsletter Ad Calculator
              </a>
            </FooterItem>
            <FooterItem>
              <a href="https://site.whosponsorsstuff.com/newsletter-subject-line-generator">
                Newsletter Subject Line Generator
              </a>
            </FooterItem>
            <FooterItem>
              <a href="https://site.whosponsorsstuff.com/newsletter-poll-generator">
                Newsletter Poll Generator
              </a>
            </FooterItem>
            <FooterItem>
              <a href="https://www.newsletterjobs.io/">NewsletterJobs.io</a>
            </FooterItem>
            <FooterItem>
              <a href="https://www.newsletterheadquarters.com/">
                Newsletter Headquarters
              </a>
            </FooterItem>
            <FooterItem>
              <a href="https://www.dineanddeliver.com/">
                Dine & Deliver: Newsletter Dinner Series
              </a>
            </FooterItem>
            <FooterItem>
              <a href="https://thenewsletterconference.com/">
                The Newsletter Conference
              </a>
            </FooterItem>
          </div>
          <div className="flex-1">
            <FooterHeader>Sponsors</FooterHeader>
            <FooterItem>
              <Link to={topSponsorsPath({})}>Top Sponsors</Link>
            </FooterItem>
            <FooterItem>
              <Link to={topSponsorsInAIPath({})}>Top Sponsors in AI</Link>
            </FooterItem>
            <FooterItem>
              <Link to={topSponsorsInB2BPath({})}>Top Sponsors in B2B</Link>
            </FooterItem>
            <FooterItem>
              <Link to={topSponsorsInCryptoAndWeb3Path({})}>
                Top Sponsors in Crypto & Web3
              </Link>
            </FooterItem>
            <FooterItem>
              <Link to={topSponsorsInEducationPath({})}>
                Top Sponsors in Education
              </Link>
            </FooterItem>
            <FooterItem>
              <Link to={topSponsorsInHealthAndFitnessPath({})}>
                Top Sponsors in Health & Fitness
              </Link>
            </FooterItem>
            <FooterItem>
              <Link to={topSponsorsInInvestingPath({})}>
                Top Sponsors in Investing
              </Link>
            </FooterItem>
            <FooterItem>
              <Link to={topSponsorsInMarketingPath({})}>
                Top Sponsors in Marketing
              </Link>
            </FooterItem>
            <FooterItem>
              <Link to={topSponsorsInSoftwarePath({})}>
                Top Sponsors in Software
              </Link>
            </FooterItem>
            <FooterItem>
              <Link to={topSponsorsInSportsPath({})}>
                Top Sponsors in Sports
              </Link>
            </FooterItem>
            <FooterItem>
              <Link to={topSponsorsInMensInterestPath({})}>
                Top Sponsors in Men's Interest
              </Link>
            </FooterItem>
            <FooterItem>
              <Link to={topSponsorsInWomensInterestPath({})}>
                Top Sponsors in Women's Interest
              </Link>
            </FooterItem>
            <FooterItem>
              <Link to={topSponsorsIn2021Path({})}>
                Top 100 Sponsors of 2021
              </Link>
            </FooterItem>
            <FooterItem>
              <Link to={topSponsorsIn2022Path({})}>
                Top 100 Sponsors of 2022
              </Link>
            </FooterItem>
            <FooterItem>
              <Link to={topSponsorsIn2023Path({})}>
                Top 100 Sponsors of 2023
              </Link>
            </FooterItem>
            <FooterItem>
              <Link to={topSponsorsIn2024Path({})}>
                Top 100 Sponsors of 2024
              </Link>
            </FooterItem>
          </div>
        </div>
        <hr className="my-10" />
        <div className="text-sm">
          © {new Date().getFullYear()} Email Revenue Solutions LLC | All rights
          reserved.
        </div>
        <div className="flex justify-between items-center gap-2 mt-3">
          <div className="flex flex-1 gap-1">
            <SocialLink
              href="https://www.linkedin.com/company/who-sponsors-stuff"
              title="LinkedIn"
            >
              <LinkedinIcon size="15" fill="white" strokeWidth="1" />
            </SocialLink>
            <SocialLink href="https://twitter.com/WhoSponsorsHQ" title="X">
              <TwitterIcon size="15" fill="white" strokeWidth="1" />
            </SocialLink>
            <SocialLink
              href="https://www.facebook.com/groups/newslettermonetization"
              title="Facebook"
            >
              <FacebookIcon size="15" fill="white" strokeWidth="1" />
            </SocialLink>
          </div>
          <div className="flex-1">
            <FooterItem>
              <a href="https://site.whosponsorsstuff.com/terms">
                Terms of Service
              </a>
            </FooterItem>
          </div>
        </div>
      </div>
    </div>
  )
}

const FooterHeader = React.forwardRef<
  HTMLDivElement,
  React.HTMLAttributes<HTMLDivElement>
>(({ className, ...props }, ref) => (
  <div ref={ref} className={cn("mb-3", className)} {...props} />
))
FooterHeader.displayName = "FooterHeader"

const FooterItem = React.forwardRef<
  HTMLDivElement,
  React.HTMLAttributes<HTMLDivElement>
>(({ className, ...props }, ref) => (
  <div ref={ref} className={cn("py-1 text-sm", className)} {...props} />
))
FooterItem.displayName = "FooterItem"

type AnchorProps = React.AnchorHTMLAttributes<HTMLAnchorElement>
export const SocialLink = ({ children, className, ...props }: AnchorProps) => {
  return (
    <a
      {...props}
      className={cn("inline-block rounded border border-white p-2", className)}
      target="_blank"
      rel="noopener noreferrer"
    >
      {children}
    </a>
  )
}
